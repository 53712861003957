import React, { useRef, useState, useEffect } from 'react';
import '../styles/video-button.scss';
import VidShot from '../images/vid-shot.jpg';
import DownArrow from '../images/down-arrow.svg';
import ReactPlayer from 'react-player';
import { gsap } from 'gsap';

const VideoButton =() => {
  let videoRef = useRef(null);
  let arrowRef = useRef(null);
  let arrowImgRef = useRef(null);
  let arrowTween = null;
  let lastScrollTop = 0;
  const [playingVideo, setPlayingVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    gsap.set('.video-button-harness', { scale: .9, alpha: 0 });
    gsap.to('.video-button-harness', { duration: .3, delay: .2, scale: 1, alpha: 1, ease: 'sine.in' });
    gsap.to(arrowRef.current, { delay: 1.5, duration: .4, alpha: 1, ease: 'sine.in' });
    arrowTween = gsap.to(arrowImgRef.current, { duration: .8, y: 30, ease: 'sine.in', repeat: -1, yoyo: true });
    if (typeof window !== 'undefined') window.addEventListener('scroll', onScroll);

    setVideoUrl('https://stream.mux.com/8ny65hMxiZr5l01Z5TOT9S5M8m6D9Im9Z.m3u8');
  }, []);

  const onScroll = (e) => {
    if (typeof window !== 'undefined') {
      const scrollTop = document.scrollingElement.scrollTop;
      const scrollingDown = scrollTop > lastScrollTop;
      const windowHeight = window.innerHeight;

      if (scrollTop < windowHeight) {
        if (scrollTop < windowHeight / 3) {
          setPlayingVideo(false);
          videoRef.current.seekTo(0);
          gsap.set('.react-player', { autoAlpha: 0, display: 'none' });

          if (!scrollingDown && !arrowTween.isActive()) arrowTween.play();
        }

        if (scrollTop >= windowHeight / 3) {
          if (arrowTween.isActive()) arrowTween.pause();
        }
      }

      lastScrollTop = scrollTop;
    }
  }

  const showPlayVideo = () => {
    setPlayingVideo(true);
    gsap.to('.react-player', { duration: .3, display: 'block', autoAlpha: 1, ease: 'sine.in' });
  }

  return (
    <div className='video-button-harness'>
      <div className='video-button'>
        <img className='vid-shot' src={VidShot} />
        <div className='play-button' onClick={showPlayVideo}/>
        <ReactPlayer
          url={videoUrl}
          controls={true}
          className='react-player'
          ref={videoRef}
          width='100%'
          height='100%'
          playing={playingVideo}
          playsinline
          // volume={1}
          // muted
        />
      </div>
      <div className='down-arrow' ref={arrowRef}>
        <img className='down-arrow__img' src={ DownArrow } ref={arrowImgRef} />
      </div>
    </div>
  )
}

export default VideoButton;
