import React from 'react';
import '../styles/sidebar.scss';
import '../styles/button.scss';
import BiotronikLogo from '../images/biotronik-logo-tagline.svg';
import MissionLogo from './mission-logo';
import { BetterCounter } from './better-counter';
import Stats from './stats';

const Sidebar =() => {
  const reloadPage = () => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0);
  }

  return (
    <div className='sidebar'>
      <Stats />
      <div className='sidebar__white-bar'>
        <img className='biotronik-logo' src={BiotronikLogo} alt='Biotronik'/>
        <MissionLogo />
        <div className='tagline'>
          <div className='tagline__line'>
            Even better deliverability for the outstanding Orsiro DES<span className='sup'>1</span>
          </div>
          {/* <div className='tagline__line'>Ultrathin struts.<span className='sup'>1</span></div>
          <div className='tagline__line'>Outstanding patient outcomes.<span className='sup'>2</span></div>
          <div className='tagline__line tagline__line--pink'>Next level of deliverability.<span className='sup'>3</span></div> */}
        </div>
        <a className='button button-more' href='http://www.orsiro.com/en/orsiro-mission?utm_source=website%20referral&utm_medium=link&utm_campaign=orsiro%20mission%20splashpage'>Find out more</a> 
        {/* https://www.orsiro.com/en/orsiro-mission */}
        <BetterCounter />
        <div className='button-watch-harness'>
          <a className='button button-watch' onClick={() => reloadPage()}>Watch the video</a>
        </div>
      </div>
    </div>
  )
}

export default Sidebar;
