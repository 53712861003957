import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import '../styles/stats.scss';
import { getNumberWithCommas } from '../modules/utils';

const Stats = () => {
  const [patientsEnrolled, setPatientsEnrolled] = useState(55000);
  const [patientsPlanned, setPatientsPlanned] = useState(71500);
  const [studiesStarted, setStudiesStarted] = useState(68);
  const [studiesPlanned, setStudiesPlanned] = useState(70);

  return (
    <div className='stats'>
      <div className='stats__title'>Outstanding patient outcomes<span className='sup'>2</span></div>

      <div className='stats__harness'>
        <div className='stat-harness stat-harness--1'>
          <div className='stat'>
            <div className='stat__number'>>{getNumberWithCommas(Math.round(patientsEnrolled))}</div>
            <div className='stat__title'>patients enrolled<span className='sup'>*</span></div>
          </div>

          <div className='stat'>
            <div className='stat__number'>>{getNumberWithCommas(Math.round(patientsPlanned))}</div>
            <div className='stat__title'>patients enrolled or planned in total<span className='sup'>*</span></div>
          </div>
        </div>

        <div className='stat-harness stat-harness--2'>
          <div className='stat'>
            <div className='stat__number'>>{getNumberWithCommas(Math.round(studiesStarted))}</div>
            <div className='stat__title'>studies started<span className='sup'>*</span></div>
          </div>

          <div className='stat'>
            <div className='stat__number'>>{getNumberWithCommas(Math.round(studiesPlanned))}</div>
            <div className='stat__title'>studies started or planned in total<span className='sup'>*</span></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Stats;

export const killStatsAnim = () => {
  gsap.killTweensOf('.stat');
  gsap.killTweensOf('.stat-harness--1 .stat');
  gsap.killTweensOf('.stat-harness--2 .stat');
  gsap.set('.stat', { alpha: 0, x: 60 });
}

export const getStatsAnim = () => {
  let tl = gsap.timeline({ delay: .5, repeat: -1, repeatDelay: .5 });
  tl.set('.stat', { alpha: 0, x: 60 })
    .set('.stat-harness--1 .stat', { alpha: 0, x: 60 })
    .set('.stat-harness--2 .stat', { alpha: 0, x: 60 })
    .to('.stat-harness--1 .stat', { duration: .4, alpha: 1, x: 0, stagger: .2, ease: 'sine.out' })
    .to('.stat-harness--1 .stat', { delay: 5, duration: .1, alpha: 0, stagger: .1, ease: 'sine.in' })

    .to('.stat-harness--2 .stat', { delay: .5, duration: .4, alpha: 1, x: 0, stagger: .2, ease: 'sine.out' })
    .to('.stat-harness--2 .stat', { delay: 5, duration: .1, alpha: 0, stagger: .1, ease: 'sine.in' });
  tl.pause();
  return tl;
}
