import React, { useState } from 'react';
import '../styles/better-counter.scss';
import { getStatsAnim } from './stats';

import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin.js';
gsap.registerPlugin(CSSPlugin);

export const BetterCounter = () => {
  return (
    <div className='better-counter'>
      <div className='better-counter__title'>
        <div className='push'>1<span className='lowercase'>st</span> in PUSH</div>
        <div className='track'>1<span className='lowercase'>st</span> in TRACK</div>
        <div className='cross'>1<span className='lowercase'>st</span> in CROSS</div>
      </div>

      <div className='better-counter__perc'>
        <div className='plus-minus'>
          <div className='push'>+</div>
          <div className='track'>-</div>
          <div className='cross'>-</div>
        </div>
        <div className='number-roll'></div>
        %
      </div>

      <div className='better-counter__description'>
        <div className='dummy-longest'>less force needed to cross demanding anatomies<span className='sup'>2</span></div>
        <div className='push'>more force from hub to tip<span className='sup'> 2</span></div>
        <div className='track'>less force needed to follow the path to the lesion<span className='sup'> 2</span></div>
        <div className='cross'>less force needed to cross demanding anatomies<span className='sup'> 3</span></div>
      </div>
    </div>
  )
}

export const getBetterSidebarAnim = (duration) => {
  // console.log('getBetterSidebarAnim');
  let tl = gsap.timeline({ smoothChildTiming: true });
  const initial = duration * .03;
  let countUp = { value: 0 };
  let statsTimeline = getStatsAnim();

  tl.set('.better-counter', { y: '15vh', alpha: 0 }, '<')
    .set('.tagline', { display: 'block', alpha: 1}, '<')
    .set('.better-counter', { position: 'absolute' }, '<')
    .set('.button-more', { display: 'inline-block', alpha: 1}, '<')
    .set('.dots__dot', { alpha: .5, backgroundColor: '#6E6E6E' }, '<')
    .set('#dot1', { alpha: 1, backgroundColor: '#D51566' }, '<')
    .set('#footnote-123', { autoAlpha: 1 }, '<')
    .set('#footnote-4', { autoAlpha: 0 }, '<')
    .set('#footnote-5', { autoAlpha: 0 }, '<')
    .set('#footnote-proven', { autoAlpha: 0 }, '<')
    .set('.footnotes .down-arrow', { autoAlpha: 0 }, '<')

    .add(gsap.delayedCall(0, () => {
      document.querySelector('.mission-logo').classList.remove('mission-logo--retracted');
    }), '<.02')

    // logo
    .to('.mission-logo', {
      duration: initial,
      className: '+=mission-logo--retracted',
      ease: 'sine.inOut'
    })

    .to('.footnotes .down-arrow', { duration: initial, autoAlpha: 0, ease: 'sine.in' }, '<')

    // tagline
    .to('.tagline', { duration: duration * .02, display: 'none', alpha: 0, ease: 'sine.inOut' }, '<')

    .set('#footnote-123', { autoAlpha: 1 }, '<')
    .set('#footnote-4', { autoAlpha: 0 }, '<')
    .set('#footnote-5', { autoAlpha: 0 }, '<')
    .set('#footnote-proven', { autoAlpha: 0 }, '<')

    // PUSH
    .set('.dots__dot', { alpha: .5, backgroundColor: '#6E6E6E' })
    .set('#dot2', { alpha: 1, backgroundColor: '#D51566' })

    .set('.better-counter .cross', { autoAlpha: 0 }, '<')
    .set('.better-counter .track', { autoAlpha: 0 }, '<')
    .set('.better-counter .push', { autoAlpha: 1 }, '<')
    .set('.better-counter', { position: 'absolute' }, '<')
    .to('.better-counter', { duration: duration * .01, autoAlpha: 1, position: 'relative', ease: 'sine.in' })
    .to('.better-counter', { duration: duration * .02, y: 0, ease: 'sine.Out' }, '<')
    .to('.footnotes .down-arrow', { duration: duration * .01, autoAlpha: 1, ease: 'sine.in' }, '<')

    .to('#footnote-123', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('#footnote-4', { duration: duration * .01, autoAlpha: 1, ease: 'sine.in' }, '<')

    .to(countUp, { duration: duration * .04, value: 57, ease: 'expo.Out', onUpdate: () => {
        document.querySelector('.number-roll').innerText = `${Math.round(countUp.value)}`;
      }}, '<')

    .set('.tagline', { display: 'none', alpha: 0})

    // repeat PUSH
    .to('.better-counter .cross', { duration: duration * .1, autoAlpha: 0 })
    .to('.better-counter .track', { duration: duration * .1, autoAlpha: 0 }, '<')
    .to('.better-counter .push', { duration: duration * .1, autoAlpha: 1 }, '<')

    // TRACK
    .set('.dots__dot', { alpha: .5, backgroundColor: '#6E6E6E' })
    .set('#dot3', { alpha: 1, backgroundColor: '#D51566' })

    .to(countUp, { duration: duration * .04, value: 30, ease: 'expo.Out', onUpdate: () => {
        document.querySelector('.number-roll').innerText = `${Math.round(countUp.value)}`;
      }}, '<')

    .to('.better-counter .cross', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('.better-counter .push', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('.better-counter .track', { duration: duration * .01, autoAlpha: 1, ease: 'sine.in' }, '<')

    // repeat TRACK
    .to('.better-counter .cross', { duration: duration * .08, autoAlpha: 0 })
    .to('.better-counter .push', { duration: duration * .08, autoAlpha: 0 }, '<')
    .to('.better-counter .track', { duration: duration * .08, autoAlpha: 1 }, '<')

    // CROSS
    .set('.dots__dot', { alpha: .5, backgroundColor: '#6E6E6E' })
    .set('#dot4', { alpha: 1, backgroundColor: '#D51566' })

    .to(countUp, { duration: duration * .04, value: 75, ease: 'expo.Out', onUpdate: () => {
        document.querySelector('.number-roll').innerText = `${Math.round(countUp.value)}`;
      }}, '<')

    .to('#footnote-4', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('#footnote-5', { duration: duration * .01, autoAlpha: 1, ease: 'sine.in' }, '<')

    .to('.better-counter .track', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('.better-counter .push', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('.better-counter .cross', { duration: duration * .01, autoAlpha: 1, ease: 'sine.in' }, '<')

    // repeat CROSS
    .to('.better-counter .track', { duration: duration * .01, autoAlpha: 0 })
    .to('.better-counter .push', { duration: duration * .01, autoAlpha: 0 }, '<')
    .to('.better-counter .cross', { duration: duration * .01, autoAlpha: 1 }, '<')

    // hide cross
    .to('.better-counter', {
      duration: duration * .04,
      autoAlpha: 0,
      ease: 'power2.in'
    }, `+=${duration * .08}`)

    .set('.tagline', { display: 'none', alpha: 0})
    .set('.dots__dot', { alpha: .5, backgroundColor: '#6E6E6E' })
    .set('#dot5', { alpha: 1, backgroundColor: '#D51566' })

    // enlarge logo
    .to('.mission-logo', {
      duration: duration * .04,
      className: '-=mission-logo--retracted',
      ease: 'sine.inOut'
    }, `<`)

    .to('#footnote-4', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')
    .to('#footnote-5', { duration: duration * .01, autoAlpha: 0, ease: 'sine.in' }, '<')

    .set('.better-counter', { position: 'relative' }, '<')
    .set('#footnote-proven', { autoAlpha: 0 }, '<')

    // show tagline
    .to('.tagline',
      { duration: duration * .01, display: 'block', alpha: 1, ease: 'sine.inOut' }
      , `<${duration * .022}`)

    .set('.better-counter', { position: 'absolute' }, '<')
    .set('.button-more', { display: 'inline-block', alpha: 1}, '<')
    .to('#footnote-proven', { duration: duration * .01, autoAlpha: 1, ease: 'sine.in' }, '<')

    .add(gsap.delayedCall(0, () => {
      statsTimeline.pause().seek(0);
    }), '<')

    // show pulse = stats
    .fromTo('.stats',
      {
        autoAlpha: 0,
        display: 'none'
      },
      {
        duration: duration * .03,
        autoAlpha: 1,
        display: 'block',
        ease: 'sine.inOut'
      }, `<`)

    .to('.button-watch-harness', {
      duration: duration * .03,
      autoAlpha: 1,
      pointerEvents: 'auto',
      position: 'relative',
      ease: 'sine.in'
    }, '<')

    .fromTo('.pulse',
      {
        autoAlpha: 0,
        y: 50,
        display: 'none'
      },
      {
        duration: duration * .03,
        autoAlpha: 1,
        y: 0,
        display: 'block',
        ease: 'sine.inOut'
      }, `<${duration * .04}`)

    .add(gsap.delayedCall(0, () => {
      // console.log('statsTimeline:', statsTimeline, 'paused?', statsTimeline.paused());
      if (statsTimeline.paused() === true) statsTimeline.play(0);
    }), `<${duration * .01}`)

    .to('.footnotes .down-arrow', { duration: duration * .007, autoAlpha: 0, ease: 'sine.in' }, `<${duration * -.02}`)
  return tl;
};
