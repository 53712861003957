import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PixiLayer from '../components/PixiLayer';
import Sidebar from '../components/sidebar';
import VideoButton from '../components/video-button';
import Pulse from '../components/pulse';
import Dots from '../components/dots';
import Footnotes from '../components/Footnotes';
import { detect } from 'detect-browser';

import { registerClassNamePlugin } from '../modules/gsap-plugins';
import IEEdgeVersion from '../components/ieedge/ie-edge-version';
registerClassNamePlugin();

const IndexPage = () => {
  const [renderPixiLayer, setRenderPixiLayer] = useState(null);
  
  useEffect(() => {
    const browser = detect();
    console.log('browser:', browser);

    const renderPixi = !(browser.name === 'ie' || (browser.name === 'edge' && parseFloat(browser.version) < 19));    
    console.log('renderPixi:', renderPixi);

    if (!renderPixi) document.body.classList.add('ie');
    setRenderPixiLayer(renderPixi);
  }, []);

  return (
    <>
      {
        renderPixiLayer === true &&
        <Layout>
          <SEO title="Home"/>
          <PixiLayer />
          <VideoButton/>
          <Sidebar/>
          <div id='scroll-height-setter'/>
          <Pulse />
          <Footnotes />
          <Dots />
        </Layout>
      }
      
      {/* IE/Edge Legacy */}
      {
        renderPixiLayer === false &&
        <Layout>
          <SEO title="Home"/>
          <IEEdgeVersion />
        </Layout>
      }
    </>    
  )
};

export default IndexPage;
