import React from 'react';
import '../styles/dots.scss';

const Dots = () => {
  return (
    <div className='dots'>
      <div id='dot1' className='dots__dot' />
      <div id='dot2' className='dots__dot' />
      <div id='dot3' className='dots__dot' />
      <div id='dot4' className='dots__dot' />
      <div id='dot5' className='dots__dot' />
    </div>
  )
}

export default Dots;
