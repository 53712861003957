import React from 'react';
import { missionLogo } from '../styles/ie-edge-version.module.scss';

const MissionLogo =() => {
  return (
    <div className='mission-logo'>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 526 116" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(-1455 -318.237)">
          <g transform="translate(1455 348.717)">
            <text transform="translate(0 69.52)" fill="#d51566" fontSize="67" fontFamily="DINPro-Medium, DINPro"
                  fontWeight="500" letterSpacing="-0.02em">
              <tspan x="0" y="0">Orsiro</tspan>
              <tspan y="0" fontSize="39.081" baselineShift="22.331100265055603">®</tspan>
              <tspan y="0" > Mission</tspan>
            </text>
            <text transform="translate(462 68.52)" fill="#8e9295" fontSize="35" fontFamily="DINPro-Regular, DINPro"
                  letterSpacing="-0.02em">
              <tspan x="0" y="0">DES</tspan>
            </text>
          </g>
          <g transform="translate(1455 301)">
            <text transform="translate(0 49.237)" fill="#d51566" fontSize="31" fontFamily="DINPro-Regular, DINPro"
                  letterSpacing="0.05em">
              <tspan x="0" y="0">NEW</tspan>
            </text>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default MissionLogo;
