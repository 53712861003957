import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';

import pushBg from '../images/push-bg.png';
import pushArm from '../images/push-arm.png';
import pushSlider from '../images/push-slider.png';

import crossBg from '../images/cross-bg.png';
import crossNeedle from '../images/cross-needle.png';

import trackBg from '../images/track-bg.png';
import trackCompass from '../images/track-compass.png';
import trackLittleDial from '../images/track-little-dial.png';
import trackRect from '../images/track-rect.png';
import trackIndicator from '../images/track-indicator.png';
import trackSlider from '../images/track-slider.png';

export const pushDrawing = () => {
  const container = new PIXI.Container();
  container.addChild(PIXI.Sprite.from(pushBg));
  container.alpha = 0;

  const arm = PIXI.Sprite.from(pushArm);
  arm.x = 17.6;
  arm.y = 133.15;
  arm.pivot = { x: 0, y: 0 };
  arm.rotation = -46.4 * PIXI.DEG_TO_RAD;
  container.addChild(arm);

  const slider = PIXI.Sprite.from(pushSlider);
  slider.x = 12;
  slider.y = 7.04;
  container.addChild(slider);

  return {
    container: container,
    arm: arm,
    slider: slider
  };
}

export const getPushDrawingAnim = (duration, bits) => {
    let tl = gsap.timeline({ smoothChildTiming: true });
    const jitterRange = 12;

    tl.set(bits.arm, { rotation: -46.4 * PIXI.DEG_TO_RAD })
      .set(bits.slider, { x: 12 })
      .set(bits.container, { alpha: 0 })

      .fromTo(bits.container, { alpha: 0 }, { duration: duration * .03, alpha: 1, ease: 'sine.easeIn' })
      .to(bits.arm, { duration: duration * .045, rotation: 0, ease: 'sine.inOut', onUpdate: () => {
        if (Math.random() > .7) bits.arm.rotation += (Math.random() * (-jitterRange/2 + Math.random() * jitterRange)) * PIXI.DEG_TO_RAD;
      } }, `+=${duration * .02}`)
      .to(bits.slider, { duration: duration * .045, x: 245.61, ease: 'sine.inOut' }, '<');

    return tl;
}

export const crossDrawing = () => {
  const container = new PIXI.Container();
  container.addChild(PIXI.Sprite.from(crossBg));
  container.alpha = 0;

  const needleLeft = PIXI.Sprite.from(crossNeedle);
  needleLeft.x = 0;
  needleLeft.y = 70.34;
  container.addChild(needleLeft);

  const needleRight = PIXI.Sprite.from(crossNeedle);
  needleRight.x = 545;
  needleRight.y = 277;
  needleRight.scale.x = -1;
  container.addChild(needleRight);

  return {
    container: container,
    needleLeft: needleLeft,
    needleRight: needleRight
  };
}

export const getCrossDrawingAnim = (duration, bits) => {
  let tl = gsap.timeline({ smoothChildTiming: true });
  const jitterRange = 70;

  tl.set(bits.container, { alpha: 0 })
    .set(bits.needleLeft, { y: 70.34 })

    .to(bits.container, { duration: duration * .03, alpha: 1, ease: 'sine.in' })
    .to(bits.needleLeft, { duration: duration * .1, y: 388.34, ease: 'expo.inOut', onUpdate: () => {
      // bits.needleRight.y = 277 - jitterRange/2 + (Math.random() * jitterRange);
      if (Math.random() > .6) gsap.to(bits.needleRight, {
        duration: duration * .001,
        y: 277 - jitterRange/2 + (Math.random() * jitterRange),
        ease: 'expo.in'
      });
    } }, `+=${duration * .001}`)

  return tl;
}

export const trackDrawing = () => {
  const container = new PIXI.Container();
  container.addChild(PIXI.Sprite.from(trackBg));
  container.alpha = 0;

  const slider = PIXI.Sprite.from(trackSlider);
  slider.x = -6.9;
  slider.y = 484.61;
  container.addChild(slider);

  const rect1 = PIXI.Sprite.from(trackRect);
  rect1.x = 4.95;
  rect1.y = 607.05;
  container.addChild(rect1);

  const rect2 = PIXI.Sprite.from(trackRect);
  rect2.x = 199.95;
  rect2.y = 607.05;
  container.addChild(rect2);

  const rect3 = PIXI.Sprite.from(trackRect);
  rect3.x = 419.95;
  rect3.y = 607.05;
  container.addChild(rect3);

  const compass = PIXI.Sprite.from(trackCompass);
  compass.x = 111;
  compass.y = 242;
  compass.pivot = { x: 34, y: 52 };
  container.addChild(compass);

  const littleDial1 = PIXI.Sprite.from(trackLittleDial);
  littleDial1.x = 64;
  littleDial1.y = 398;
  littleDial1.pivot = { x: 4, y: 18 };
  container.addChild(littleDial1);

  const littleDial2 = PIXI.Sprite.from(trackLittleDial);
  littleDial2.x = 220;
  littleDial2.y = 398;
  littleDial2.pivot = { x: 4, y: 18 };
  container.addChild(littleDial2);

  const indicator = PIXI.Sprite.from(trackIndicator);
  indicator.x = 236;
  indicator.y = 26;
  indicator.pivot = { x: 48, y: 14 };
  container.addChild(indicator);

  return {
    container: container,
    slider: slider,
    rect1: rect1,
    rect2: rect2,
    rect3: rect3,
    compass: compass,
    littleDial1: littleDial1,
    littleDial2: littleDial2,
    indicator: indicator
  };
}

export const getTrackDrawingAnim = (duration, bits) => {
  let tl = gsap.timeline({ smoothChildTiming: true });
  const jitterRange = 42;

  tl.set(bits.container, { alpha: 0 })
    .set(bits.slider, { x: -6.9 })
    .set(bits.rect1, { x: 4.95 })
    .set(bits.rect2, { x: 199.95 })
    .set(bits.rect3, { x: 419.95 })

    .to(bits.container, { duration: duration * .03, alpha: 1, ease: 'sine.easeIn' })
    .to(bits.slider, { duration: duration * .08, x: 468.8, ease: 'expo.easeInOut'}, `+=${duration * .001}`)
    .to(bits.rect1, { duration: duration * .02, x: 151.95, ease: 'power2.inOut', yoyo: true, repeat: 10}, '<')
    .to(bits.rect2, { duration: duration * .022, x: 371.95, ease: 'power2.inOut', yoyo: true, repeat: 10}, '<')
    .to(bits.rect3, { duration: duration * .023, x: 717.95, ease: 'power2.inOut', yoyo: true, repeat: 10, onUpdate: () => {
      if (Math.random() > .45) bits.compass.rotation = (Math.random() * (-jitterRange/2 + Math.random() * jitterRange)) * PIXI.DEG_TO_RAD;
      if (Math.random() > .45) bits.littleDial1.rotation = (Math.random() * (-jitterRange/2 + Math.random() * jitterRange)) * PIXI.DEG_TO_RAD;
      if (Math.random() > .45) bits.littleDial2.rotation = (Math.random() * (-jitterRange + Math.random() * jitterRange*2)) * PIXI.DEG_TO_RAD;
    }}, '<')
    .to(bits.indicator, { duration: duration * .08, motionPath: [
      { x: 357, y: 121 },      
      { x: 525, y: 232 },      
      { x: 612, y: 307 },      
      { x: 705, y: 431 },      
    ], ease: 'sine.easeOut' }, `<${duration * .004}`)

  return tl;
}
