import React, { useEffect, useRef } from 'react';
import '../styles/footnotes.scss';
import DownArrow from '../images/down-arrow.svg';
import { gsap } from 'gsap';

const Footnotes = () => {
  let arrowRef = useRef(null);
  let arrowImgRef = useRef(null);
  let arrowTween = null;

  useEffect(() => {
    arrowTween = gsap.to(arrowImgRef.current, { duration: .8, y: 30, ease: 'sine.in', repeat: -1, yoyo: true });
    // if (typeof window !== 'undefined') window.addEventListener('scroll', onScroll);
  }, []);

  const onScroll = (e) => {
    // if (typeof window !== 'undefined') {
    //   const scrollTop = document.scrollingElement.scrollTop;
    //   const scrollingDown = scrollTop > lastScrollTop;
    //   const windowHeight = window.innerHeight;
    //
    //   if (scrollTop < windowHeight) {
    //     if (scrollTop < windowHeight / 3) {
    //       setPlayingVideo(false);
    //       videoRef.current.seekTo(0);
    //       gsap.set('.react-player', { autoAlpha: 0, display: 'none' });
    //
    //       if (!scrollingDown && !arrowTween.isActive()) arrowTween.play();
    //     }
    //
    //     if (scrollTop >= windowHeight / 3) {
    //       if (arrowTween.isActive()) arrowTween.pause();
    //     }
    //   }
    // }
  }

  return (
    <div className='footnotes'>
      <div className='down-arrow' ref={arrowRef}>
        <img className='down-arrow__img' src={ DownArrow } ref={arrowImgRef} />
      </div>
      <div id='footnote-123' className='footnotes__footnote'>
        <sup>1 </sup> In comparison to Xience Sierra, Resolute Onyx and Synergy for bench tests on pushability, trackability and crossability, BIOTRONIK data on file.
      </div>

      <div id='footnote-4' className='footnotes__footnote'><sup>2 </sup>In comparison to Resolute Onyx.</div>
      <div id='footnote-5' className='footnotes__footnote'><sup>3 </sup>In comparison to Synergy.</div>

      <div id='footnote-proven' className='footnotes__footnote'>
        <sup>*</sup>BIOTRONIK data on file, status January 2020. <sup>1 </sup> In comparison to Xience Sierra, Resolute Onyx and Synergy for bench tests on pushability, trackability and crossability, BIOTRONIK data on file; <sup>2 </sup>Based on investigator's interpretation of BIOFLOW-V primary endpoint result; Orsiro and Orsiro Mission are trademarks or registered trademarks of the BIOTRONIK Group of Companies. Synergy is a trademark or registered trademark of the Boston Scientific group of companies. Resolute Onyx is a trademark or registered trademark of the Medtronic group of companies. Clinical data conducted with Orsiro, Orsiro Mission’s predecessor device can be used to illustrate Orsiro Mission clinical outcomes;
        <div className='terms-links'>
          <a href={'https://www.orsiro.com/en/general-terms-and-conditions'}>General terms and conditions</a>
          <a href={'https://www.orsiro.com/en/imprint'}>Imprint</a>
          <a href={'https://www.orsiro.com/en/legal-disclaimer'}>Legal disclaimer</a>
        </div>
      </div>
    </div>
  )
}

export default Footnotes;
