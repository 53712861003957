import React, { useEffect } from 'react';
import styles from '../../styles/ie-edge-version.module.scss';
import BiotronikLogo from '../../images/biotronik-logo-tagline.svg';
import pushImage from '../../images/ie/push.jpg';
import trackImage from '../../images/ie/track.jpg';
import crossImage from '../../images/ie/cross.jpg';
import dividerPush from '../../images/ie/divider-push-1x.png';
import dividerTrack from '../../images/ie/divider-track-1x.png';
import dividerCross from '../../images/ie/divider-cross-1x.png';
import dividerFinal from '../../images/ie/divider-final-1x.png';
import '../../styles/sidebar.scss';
import '../../styles/button.scss';
import '../../styles/better-counter.scss';
import classNames from 'classnames';
import VideoButton from '../video-button';
import pulseSvg from '../../images/ie/pulse.svg';
import { getNumberWithCommas } from '../../modules/utils';

const FindOutMoreButton = () => (
  <a className='button button-more' href='http://www.orsiro.com/en/orsiro-mission?utm_source=website%20referral&utm_medium=link&utm_campaign=orsiro%20mission%20splashpage'>
    Find out more
  </a>
);

const reloadPage = () => {
  if (typeof window !== 'undefined') window.scrollTo(0, 0);
}
const WatchNowButton = () => (
  <div className={styles.watchNowButtonHarness}>
    <a className='button button-watch' onClick={() => reloadPage()}>
      Watch the video
    </a>
  </div>
);

const MissionLogo = () => {
  return (
    <div className={styles.missionLogo}>
      <svg viewBox="0 0 526 116" preserveAspectRatio="xMidYMid meet">
        <text
          transform="translate(-302 -301) translate(302 401)"
          fill="#d51566"
          fontSize={67}
          fontFamily="DINPro-Medium, DINPro"
          fontWeight={500}
          letterSpacing="-0.02em"
        >
          <tspan x={0} y={0}>
            {"Orsiro"}
          </tspan>
        </text>
        <text
          transform="translate(-302 -301) translate(528 401)"
          fill="#d51566"
          fontSize={67}
          fontFamily="DINPro-Medium, DINPro"
          fontWeight={500}
          letterSpacing="-0.02em"
        >
          <tspan x={0} y={0}>
            {"Mission"}
          </tspan>
        </text>
        <path
          d="M511.412 365.087a14.144 14.144 0 00-14.147-14.147 14.144 14.144 0 00-14.147 14.147 14.144 14.144 0 0014.147 14.147 14.144 14.144 0 0014.147-14.147zm-2.853 0a11.3 11.3 0 01-11.294 11.529 11.336 11.336 0 01-11.334-11.529 11.336 11.336 0 0111.334-11.529 11.3 11.3 0 0111.294 11.529zm-5.159 7.621l-3.4-6.448a4.258 4.258 0 003.009-4.143 4.735 4.735 0 00-4.924-4.69h-5.745v15.281h2.814v-6.058h1.954l3.126 6.058zm-3.166-10.591a2.337 2.337 0 01-2.5 2.306h-2.579v-4.612h2.579a2.337 2.337 0 012.501 2.306z"
          fill="#d51566"
          transform="translate(-302 -301)"
        />
        <text
          transform="translate(-302 -301) translate(764 400)"
          fill="#8e9295"
          fontSize={35}
          fontFamily="DINPro-Regular, DINPro"
          letterSpacing="-0.02em"
        >
          <tspan x={0} y={0}>
            {"DES"}
          </tspan>
        </text>
        <text
          transform="translate(-302 -301) translate(302 333)"
          fill="#d51566"
          fontSize={31}
          fontFamily="DINPro-Regular, DINPro"
          letterSpacing="0.05em"
        >
          <tspan x={0} y={0}>
            {"NEW"}
          </tspan>
        </text>
      </svg>
    </div>
  )
}

const Footnote = ({ id, showBackground = false }) => {
  return (
    <div className={classNames(styles.footnotes, showBackground ? styles.footnotesBackground : '')}>
      {/* <div className='down-arrow' ref={arrowRef}>
        <img className='down-arrow__img' src={ DownArrow } ref={arrowImgRef} />
      </div> */}

      {
        id === 123 &&
        <div className={styles.footnote}>
          <sup>1 </sup> In comparison to Xience Sierra, Resolute Onyx and Synergy for bench tests on pushability, trackability and crossability, BIOTRONIK data on file.
        </div>
      }

      {
        id === 4 &&
        <div className={styles.footnote}><sup>2 </sup>In comparison to Resolute Onyx.</div>
      }

      {
        id === 5 &&
        <div className={styles.footnote}><sup>3 </sup>In comparison to Synergy.</div>
      }

      {
        id === 'final' &&
        <div className={styles.footnote}>
          <sup>*</sup>BIOTRONIK data on file, status January 2020. <sup>1 </sup> In comparison to Xience Sierra, Resolute Onyx and Synergy for bench tests on pushability, trackability and crossability, BIOTRONIK data on file; Orsiro and Orsiro Mission are trademarks or registered trademarks of the BIOTRONIK Group of Companies. Synergy is a trademark or registered trademark of the Boston Scientific group of companies. Resolute Onyx is a trademark or registered trademark of the Medtronic group of companies. Clinical data conducted with Orsiro, Orsiro Mission’s predecessor device can be used to illustrate Orsiro Mission clinical outcomes. 

          <div className={styles.termsLinks}>
            <a href={'https://www.orsiro.com/en/general-terms-and-conditions'}>General terms and conditions</a>
            <a href={'https://www.orsiro.com/en/imprint'}>Imprint</a>
            <a href={'https://www.orsiro.com/en/legal-disclaimer'}>Legal disclaimer</a>
          </div>
        </div>
      }
    </div>
  )
}

const Stats = () => {
  return (
    <div className={styles.stats}>
      <div className={styles.statsTitle}>Proven Orsiro performance<span className='sup'>*</span></div>

      <div className={styles.statsHarness}>
        <div className={styles.stat}>
          <div className={styles.statNumber}>{'>55,000'}</div>
          <div className={styles.statTitle}>patients enrolled</div>
        </div>

        <div className={styles.stat}>
          <div className={styles.statNumber}>{'>68'}</div>
          <div className={styles.statTitle}>studies started</div>
        </div>

        <div className={styles.stat}>
          <div className={styles.statNumber}>{'>71,500'}</div>
          <div className={styles.statTitle}>patients enrolled or planned in total</div>
        </div>

        <div className={styles.stat}>
          <div className={styles.statNumber}>{'>70'}</div>
          <div className={styles.statTitle}>studies started or planned in total</div>
        </div>
      </div>
    </div>
  )
}

const IEEdgeVersion = () => {
  return (
    <div className={styles.ieEdgeVersion}>
      <div className={styles.biotronikLogoHarness}>
        <img className={styles.biotronikLogo} src={BiotronikLogo} alt='Biotronik' />
      </div>

      {/* VIDEO */}
      <div className={classNames(styles.strip, styles.stripNoImage)}>
        <div className={styles.left}>
          <VideoButton />
          <Footnote id={123} />
        </div>
        <div className={styles.sideBar}>
          <div>
            <MissionLogo />
            <div className={styles.tagline}>
              <div className={styles.taglineLine}>
                Even better deliverability for the outstanding Orsiro DES<span className={styles.taglineSup}>1</span>
              </div>
            </div>
            <FindOutMoreButton />
          </div>
        </div>
      </div>

      {/* PUSH */}
      <div className={styles.strip}>
        <div className={styles.left}>
          <img src={pushImage} className={styles.stentImage} />
          <img src={dividerPush} className={styles.divider} />
          <Footnote id={4} />
        </div>
        <div className={styles.sideBar}>
          <div>
            <MissionLogo />
            <div className='better-counter'>
              <div className='better-counter__title'>1st in PUSH</div>
              <div className='better-counter__perc'>+57%</div>
              <div className='better-counter__description'>
                more force from hub to tip<span className='sup'> 2</span>
              </div>
            </div>
            <FindOutMoreButton />
          </div>
        </div>
      </div>

      {/* TRACK */}
      <div className={styles.strip}>
        <div className={styles.left}>
          <img src={trackImage} className={styles.stentImage} />
          <img src={dividerTrack} className={styles.divider} />
          <Footnote id={4} />
        </div>
        <div className={styles.sideBar}>
          <div>
            <MissionLogo />
            <div className='better-counter'>
              <div className='better-counter__title'>1st in TRACK</div>
              <div className='better-counter__perc'>-30%</div>
              <div className='better-counter__description'>
                less force needed to follow the path to the lesion<span className='sup'> 2</span>
              </div>
            </div>
            <FindOutMoreButton />
          </div>
        </div>
      </div>

      {/* CROSS */}
      <div className={styles.strip}>
        <div className={styles.left}>
          <img src={crossImage} className={styles.stentImage} />
          <img src={dividerCross} className={styles.divider} />
          <Footnote id={5} />
        </div>
        <div className={styles.sideBar}>
          <div>
            <MissionLogo />
            <div className='better-counter'>
              <div className='better-counter__title'>1st in CROSS</div>
              <div className='better-counter__perc'>-75%</div>
              <div className='better-counter__description'>
                less force needed to cross demanding anatomies<span className='sup'> 3</span>
              </div>
            </div>
            <FindOutMoreButton />
          </div>
        </div>
      </div>

      {/* FINAL */}
      <div className={classNames(styles.strip, styles.stripNoImage)}>
        <div className={styles.left}>
          <img src={pulseSvg} className={styles.pulse} />
          <img src={dividerFinal} className={styles.divider} />
          <Stats />
          <Footnote id={'final'} showBackground />
        </div>
        <div className={styles.sideBar}>
          <div>
            <MissionLogo />
            <div className={styles.tagline}>
              <div className={styles.taglineLine}>
                Even better deliverability for the outstanding Orsiro DES<span className={styles.taglineSup}>1</span>
              </div>
            </div>
            <FindOutMoreButton />
            <WatchNowButton />
          </div>
        </div>
      </div>
    </div>
  )
};

export default IEEdgeVersion;