import React, { useEffect, useRef, useState } from 'react';
import * as PIXI from 'pixi.js';
import { gsap } from 'gsap';
import { PixiPlugin } from 'gsap/PixiPlugin.js';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin.js';
import useMedia from '../hooks/use-media';

import Stent from '../images/stent-long.jpg';
import { getBetterSidebarAnim } from './better-counter';
import { pushDrawing, getPushDrawingAnim, crossDrawing, getCrossDrawingAnim, trackDrawing, getTrackDrawingAnim } from '../modules/drawings';

gsap.registerPlugin(PixiPlugin, MotionPathPlugin);

const PixiLayer = () => {
  const pixiCanvas = useRef(null);
  const pixiCanvasSize = useRef(null);

  const [animScale, setAnimScale] = useState(1);
  const [animOffsetY, setAnimOffsetY] = useState(0);

  const duration = 60;
  const currentScrollPercent = useRef(0);
  const timelineContainer = useRef(null);
  const app = useRef(null);
  const resolution = useRef(typeof window !== 'undefined' ? window.devicePixelRatio : 1);

  const mainTimeline = useRef(null);
  const betterSidebarAnim = useRef(null);
  const points = useRef(null);

  const pushBits = useRef(null);
  const pushDrawingAnim = useRef(null);

  const crossBits = useRef(null);
  const crossDrawingAnim = useRef(null);

  const trackBits = useRef(null);
  const trackDrawingAnim = useRef(null);

  let stripContainer, scrollBox;

  useEffect(() => {
    app.current = new PIXI.Application({
      resizeTo: pixiCanvasSize.current,
      backgroundColor: 0xF8F8F8,
      resolution: resolution.current,
      autoDensity: true,
      view: pixiCanvas.current,
      antialias: true
    });
    app.current.resize();

    createContainers();
    createRope();
    createDrawings();
    initScrolling();

    if (typeof window !== 'undefined') window.addEventListener('resize', resize);
    resize();

    initTimeline();
  }, []);

  useEffect(() => {
    initTimeline();

    if (timelineContainer.current) {
      timelineContainer.current.scale = { x: animScale, y: animScale };
      timelineContainer.current.x = app.current.view.width * .65 / resolution.current;
    }

    // console.log('animScale set to:', animScale);
    // console.log('timelineContainer:', timelineContainer.current);
  }, [animScale, animOffsetY]);

  const resize = () => {
    resetAnimScale();
  };

  const resetAnimScale = () => {
    const sidebar = document.querySelector('.sidebar__white-bar');
    let offsetY = 0;

    if (typeof window !== 'undefined') {
      if (window.innerHeight <= 650 && window.innerWidth > 900) offsetY = -sidebar.clientHeight * .5;
      else if (window.innerWidth <= 800 && window.innerHeight < window.innerWidth) offsetY = -sidebar.clientHeight * .175;
      else if (window.innerWidth < 992) offsetY = sidebar.clientHeight;
    }

    // console.log('resetAnimScale :: window.innerHeight', window.innerHeight);
    // console.log('resetAnimScale :: window.innerWidth', window.innerWidth);
    // console.log('resetAnimScale :: sidebar.clientHeight', sidebar.clientHeight);
    // console.log('resetAnimScale :: offsetY:', offsetY);
    // console.log('resetAnimScale :: animScale:', Math.min(1, pixiCanvasSize.current.clientWidth / 1050));

    setAnimScale(Math.min(1, pixiCanvasSize.current.clientWidth / 1050));
    setAnimOffsetY(offsetY);
  };



  const initTimeline = () => {
    // console.log('init timeline... animScale:', animScale);
    resetAnimScale();

    // console.log('mainTimeline:', mainTimeline.current);
    if (mainTimeline.current) mainTimeline.current.kill();

    mainTimeline.current = gsap.timeline({ smoothChildTiming: true });
    mainTimeline.current.pause();
    mainTimeline.current.set('.dots__dot', { alpha: .5, backgroundColor: '#6E6E6E' });
    mainTimeline.current.set('#dot1', { alpha: 1, backgroundColor: '#D51566' });
    mainTimeline.current.set('.button-watch-harness', {
      autoAlpha: 0,
      pointerEvents: 'none',
      position: 'absolute'
    });
    mainTimeline.current.set(timelineContainer.current, { y: 0 });

    // console.log('==> animScale:', animScale, 'animOffsetY:', animOffsetY);

    mainTimeline.current.to(points.current, {
        duration: duration,
        delay: 2,
        motionPath: '#stent-path',
        stagger: duration * 0.002333333333
      })

      // main camera moves
      .to(timelineContainer.current, {
        duration: duration * .1,
        y: (-150 * animScale) + animOffsetY,
        ease: 'sine.out'
      }, '<')

      .to(timelineContainer.current, {
        duration: duration * .12,
        y: (-1200 * animScale) + animOffsetY,
        ease: 'sine.inOut'
      }, `<${duration * .1}`)

      .to(timelineContainer.current, {
        duration: duration * .1,
        y: (-2300 * animScale) + animOffsetY,
        ease: 'sine.inOut'
      }, `<${duration * .12}`)

      .to(pixiCanvas.current, { alpha: 1 }, `<${duration * .19}`)

      .to(timelineContainer.current, {
        duration: duration * .1,
        y: (-2700 * animScale) + animOffsetY,
        ease: 'power2.in'
      }, `<-${duration * .07}`)

    // better sidebar anim
    mainTimeline.current.add(betterSidebarAnim.current, 0);

    // drawing anims
    mainTimeline.current.add(pushDrawingAnim.current, duration * .025);
    mainTimeline.current.add(crossDrawingAnim.current, duration * .3);
    mainTimeline.current.add(trackDrawingAnim.current, duration * .14);

    gsap.set(mainTimeline.current, { progress: currentScrollPercent.current});
  };

  const initScrolling = () => {
    scrollBox = document.getElementById('scroll-height-setter');

    if (typeof window !== 'undefined') window.addEventListener('scroll', (e) => {
      const scrollMax = scrollBox.clientHeight - (typeof window !== 'undefined' ? 0 : window.innerHeight);
      const percent = document.scrollingElement.scrollTop / scrollMax;
      currentScrollPercent.current = Math.max(0, percent * .4);
      gsap.set(mainTimeline.current, { progress: currentScrollPercent.current});
    }, { passive: true });
  };

  const createContainers = () => {
    stripContainer = new PIXI.Container();
    stripContainer.rotation = 90 * PIXI.DEG_TO_RAD;

    timelineContainer.current = new PIXI.Container();
    timelineContainer.current.scale = { x: animScale, y: animScale };
    timelineContainer.current.addChild(stripContainer);
    app.current.stage.addChild(timelineContainer.current);
  };

  const createDrawings = () => {
    pushBits.current = pushDrawing();
    pushBits.current.container.x = -440;
    pushBits.current.container.y = 375;
    timelineContainer.current.addChildAt(pushBits.current.container, 0);

    trackBits.current = trackDrawing();
    trackBits.current.container.x = -610;
    trackBits.current.container.y = 1440;
    timelineContainer.current.addChildAt(trackBits.current.container, 0);

    crossBits.current = crossDrawing();
    crossBits.current.container.x = -275;
    crossBits.current.container.y = 2500;
    timelineContainer.current.addChildAt(crossBits.current.container, 0);

    betterSidebarAnim.current = getBetterSidebarAnim(duration);
    pushDrawingAnim.current = getPushDrawingAnim(duration, pushBits.current);
    crossDrawingAnim.current = getCrossDrawingAnim(duration, crossBits.current);
    trackDrawingAnim.current = getTrackDrawingAnim(duration, trackBits.current);
  };

  const createRope = () => {
    const ropeLength = 40;
    points.current = [];

    for (let i = 0; i < 150; i++) {
      points.current.push(
        new PIXI.Point(
          (ropeLength - i) * ropeLength - ropeLength * ropeLength,
          603
        )
      );
    }

    const strip = new PIXI.SimpleRope(PIXI.Texture.from(Stent), points.current);
    strip.x = 0;
    strip.y = -603;

    stripContainer.addChild(strip);
  };

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="635.573"
        height="3741.336"
        viewBox="0 0 635.573 3741.336"
        id='stent-path-svg'
      >
        <path
          id="stent-path"
          dataname="Path 1"
          d="M0,602.828s414.438,1.519,523.8,0c154.939,5.268,251.62,94.3,323.875,188.139,78.591,102.07,106.353,213.521,315.635,212.789,214.964,0,301.894-194.608,430.246-367.489,100.317-135.12,226.32-256.981,459.768-260.047,251.875-3.308,358.815,226.254,634.334,226.608,71.578.092,355.51.131,724.9.14,410.643.01,926.689-.017,1371.447-.05,544.137-.041,980.539-.09,980.539-.09"
          transform="translate(1007.76 0.009) rotate(90)"
          fill="none"
          stroke="#ebff00"
          strokeWidth="8"
        />
      </svg>
      <div id='pixi-canvas-size' ref={pixiCanvasSize}>
        <canvas id='pixi-canvas' ref={pixiCanvas}></canvas>
      </div>
    </>
  );
};

export default PixiLayer;
